@import '../../theme.scss';

.requestinstructor-page {
    height: 100%;
    padding-bottom: 25px;
    overflow-y: auto;
    .spinner {
        width: 25px;
        height: 25px;
        border-width: 5px;
    }
    .btn-2 {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .request-container {
        max-width: 560px;
        h1 {
            text-align: center;
            font-size: 24px;
        }
        h2 {
            font-size: 15px;
            line-height: 23px;
        }
        .owl-theme {
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                left: -30px;
                right: -30px;
                pointer-events: none;
                .owl-prev, .owl-next {
                    pointer-events: auto;
                    outline: none;
                    &:hover {
                        background: none;
                    }
                }
            }
            .owl-stage-outer {
                .owl-stage {
                    display: flex;
                }
            }
        }

        .instructor-date {
            font-weight: 900;
            cursor: pointer;
            border: 2px solid #E7EBEE;
            background: white;
            border-radius: 4px;
            height: 113px;
            text-align: center;
    
            &.active {
                color: white;
                background: $secondary-text;
            }
            .month {
                font-size: 18px;
            }
            .day {
                font-size: 36px;
            }
            .weekday {
                font-weight: normal;
                font-size: 13px;
                color: #7B88A0;
            }
            &.active {
                background-color: $primary-text;
                color: white;
            }
        }
        .option-group {
            margin-bottom: 25px;

            .option-select {
                .selectbox-container {
                    background-color: white;
                    border: 2px solid #E3E8EC;
                    height: 40px;
                    padding: 0 15px 0 12px;
                    .svg-icon {
                        svg {
                            fill: $primary-color !important;
                        }
                        &.start-icon {
                            svg {
                                fill: #C5CBD7 !important;
                                margin-left: 0;
                            }
                        }
                    }
                    
                }
                .options {
                    border-width: 2px;
                    .option {
                        height: 40px;
                        padding-left: 15px;
                    }
                }
            }

            .option-list {
                display: flex;
                flex-wrap: wrap;
                .type-item {
                    cursor: pointer;
                    border: 2px solid #E7EBEE;
                    border-radius: 4px;
                    text-align: center;
                    p {
                        font-size: 18px;
                        font-weight: 900;
                    }
                    &.active {
                        background-color: $primary-text;
                        color: white;
                    }
                }
            }

            ::placeholder {
                color: rgba(32, 54, 98, 0.4);
            }

            .option-note {
                border: 2px solid #E3E8EC;
                font-size: 15px;
                height: 140px;
                border-radius: 4px;
            }
        }
    }
    .success-area {
        flex: 1;
        height: 100%;

        .success-content {
            text-align: center;
            h2 {
                font-size: 24px;
            }
            img {
                margin: 30px 0;
            }
            p {
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .request-container {
        padding-top: 37px;

        h1 {
            margin-bottom: 33px;
        }

        .option-group {
            .option-title {
                margin-bottom: 6px;
            }

            .option-list {
                justify-content: space-between;
                .type-item {
                    width: calc((100% - 30px) / 3);
                    height: 86px;
                }
            }

            .option-note {
                padding: 8px 13px;
            }
        }
        .submit-btn {
            margin-top: 5px;
        }
    }
    .success-area {
        .success-content {
            max-width: 530px;
        }
    }
}

@media screen and (max-width: 991px) {
    .request-container {
        padding-top: 24px;

        h1 {
            margin-bottom: 16px;
        }

        .option-group {
            .option-title {
                margin-bottom: 10px;
            }

            .option-list {
                justify-content: space-between;
                .type-item {
                    width: calc(50% - 7px);
                    height: 75px;
                    margin-bottom: 15px;
                    &:last-child {
                        margin: 0 auto;
                    }
                }
            }

            .option-note {
                padding: 11px 16px;
            }
        }
        .submit-btn {
            margin-top: -10px;
        }
    }
    .success-area {
        padding: 0 15px;
    }
}