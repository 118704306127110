@import '../../theme.scss';

.rateplan-page {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    h1 {
        font-size: 23px;
        font-weight: 900;
        text-align: center;
        margin: 50px 0 30px;
    }
    .btn-2 {
        text-decoration: none;
        background: #16d38f;
        color: white;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .spinner {
        width: 25px;
        height: 25px;
        border-width: 5px;
    }
    .plan-area {
        max-width: 100%;
        .toggle-area {
            p {
                line-height: 30px;
                &.left-option {
                    font-weight: bold;
                }
            }
            .option-toggle {
                margin: 0 19px;
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .period-row {
            border-radius: 8px;
            background: rgba($color: #2986F8, $alpha: 0.3);
            padding: 5px;
            max-width: 100%;
            overflow-x: auto;
            .period {
                padding: 13px 0;
                min-width: 146px;
                border-radius: 8px;
                cursor: pointer;
                font-size: 14px;
                font-weight: bold;
                color: white;
                white-space: nowrap;
                &.active {
                    background: white;
                    color: #2986F8;
                }
            }
        }
        .coupon-row {
            padding: 8px 15px;
            p {
                margin-bottom: 8px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 900;
                text-align: center;
            }
            input {
                border: none;
                text-align: center;
                height: 35px;
                border-radius: 10px;
            }
        }
        .pricing-body {
            width: 100%;
            display: flex;
            margin-top: 30px;
            .coding-class {
                box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.09);
                padding: 24px 30px 30px;
                background: rgba($color: $main-bg, $alpha: 0.1);
                border-radius: 10px;
                .sub-header {
                    text-align: center;
                    border-bottom: 1px solid rgba(95, 70, 193, 0.1);
                    padding-bottom: 22px;
                    h2 {
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 21px;
                        text-transform: uppercase;
                    }
                    h3 {
                        font-size: 38px;
                        line-height: 40px;
                        color: #712ABC;
                        width: 100%;
                        align-items: baseline;
                        white-space: normal;
                        span {
                            display: inline-block;
                            &.unit {
                                font-size: 15px;
                                font-weight: normal;
                            }
                        }
                        .ignore {
                            font-size: 25px;
                            text-decoration: line-through;
                            margin-right: 7px;
                            opacity: 0.4;
                            font-weight: 500;
                        }
                    }
                }
                .sub-body {
                    padding-top: 24px;
                    flex: 1;
                    .sub-item {
                        margin-bottom: 17px;
                        .svg-icon {
                            fill: #712ABC;
                            margin-right: 9px;
                        }
                        p {
                            font-weight: 500;
                        }
                    }
                    .btn-link {
                        height: 55px;
                        margin-top: auto;
                    }
                }
                &.sibiling {
                    .sub-header {
                        h3 {
                            color: #2986F8;
                        }
                    }
                    .sub-body {
                        .sub-item {
                            .svg-icon {
                                fill: #2986F8;
                            }
                        }
                    }
                }
            }
        }
    }
    .payment-area {
        .form-group {
            margin-bottom: 25px;
            .btn-2 {
                width: 100%;
                margin-top: auto;
            }
        }
        .back-icon {
            position: absolute;
            top: 27px;
            left: 32px;
            fill: $primary-color;
            cursor: pointer;
        }
        .row {
            margin: 0;
        }
        form {
            width: 100%;
        }
        .checkout-form {
            margin-bottom: 10px;
            width: 100%;
            .check-error {
                color: red;
                font-size: 16px;
            }
            .StripeElement {
                background-color: #F6F5F9;
                padding: 5px 0;
                display: flex;
                align-items: center;
                padding: 0 10px 0 20px;
                border-radius: 10px;
                height: 60px;
                .__PrivateStripeElement {
                    flex: 1;
                }
            }
        }
        .btn-2 {
            width: 100%;
        }
        .toggle-area {
            display: flex;
            .custom-toggle {
                display: inline-block;
                margin-right: 14px;
                // background-color: grey;
            }
            p {
                font-size: 15px;
                line-height: 24px;
            }
        }
        .policy {
            margin-top: 31px;
            p {
                font-size: 13px;
                line-height: 21px;
            }
        }
        .edit-card {           
            margin-bottom: 30px;

            .card-num-date {
                display: flex;
                align-items: center;
            }

            .card-brand {
                font-size: 22px;
                font-weight: 500;
            }
            .edit-card-row {
                display: flex;
                align-items: center;
                .edit-item-btn {
                    cursor: pointer;
                    text-decoration-line: underline;
                    color: $primary-color;
                    margin-right: 15px;
                }
            }
        }
        .add-new-card{
            cursor: pointer;
            text-decoration-line: underline;
            color: $primary-color;
            font-size: 17px;
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 992px) {
    .plan-area {
        padding: 24px 15px 0 30px;
        .period-row {
            margin-top: 33px;
        }
        .pricing-body {
            justify-content: center;
            .coding-class {
                width: calc(50% - 22.5px);
                max-width: 428px;
                background: white;
                margin-right: 45px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .payment-area {
        max-width: 562px;
        padding-top: 37px;

        h1 {
            margin-bottom: 26px;
        }
    }
}

@media screen and (max-width: 991px) {
    .plan-area {
        padding: 24px 15px 0 15px;
        .period-row {
            margin-top: 48px;
        }
        .pricing-body {
            flex-direction: column;
            .coding-class {
                width: 100%;
                max-width: 100%;
                background: white;
                &:first-child {
                    margin-bottom: 45px;
                }
            }
        }
    }
    .payment-area {
        padding: 11px 7.5px 0 7.5px;
    }
}