.authcheck-component {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    &.loading {
        filter: opacity(0.8);
    }
}