.roundbutton-component {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    .svg-icon {
        fill: #ADB1B5;
    }
    &.down {
        background-color: #F1F3F4;
        .svg-icon {
            fill: #23A4EF;
        }
    }
}