@import '../../theme.scss';

.myinstructors-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .instructor-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .instructors {
            flex: 1;
        }
        .instructor-container {
            cursor: pointer;
            .instructor {
                padding: 15px 0;
                border-bottom: 1px solid #F0F1F2;
                display: flex;
                .avatar {
                    margin-right: 16px;
                }
                .info {
                    flex: 1;
                    justify-content: space-between;
                    height: 45px;
                    max-width: calc(100% - 80px);
                    h4 {
                        font-size: 15px;
                        font-weight: 900;
                    }
                    p {
                        font-size: 13px;
                        color: #7B88A0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            &:hover, &.selected {
                background-color: #F4F6F7;
            }
        }
    }
    .content-area {
        flex: 1;
        display: flex;

        .instructor-profile {
            display: flex;
            border: 1px solid #F0F1F2;
            border-radius: 4px;
            padding: 15px;
            p {
                font-size: 13px;
                color: #7B88A0;
            }
            .instructor-photo {
                text-align: center;
                .avatar {
                    width: 100px;
                    height: 100px;
                    margin-bottom: 10px;
                    p {
                        font-size: 26px; 
                        color: white;
                    }
                }
            }
            .instructor-info {
                .sub-info {
                    display: flex;
                    margin-bottom: 12px;
                    .svg-icon {
                        fill: #23A4EF;
                        margin-right: 9px;
                        height: fit-content;
                    }
                }
            }
            &.web {
                flex-direction: column;
                height: fit-content;
                width: 210px;
                margin-right: 30px;
                .instructor-photo {
                    padding-bottom: 8px;
                    border-bottom: 1px solid #F0F1F2;
                    h1 {
                        font-size: 18px;
                    }
                    p {
                        margin-top: 2px;
                    }
                }
                .instructor-info {
                    padding-top: 12px;
                }
            }
            &.mobile {
                margin-top: 30px;
                .instructor-photo {
                    width: 130px;
                    padding-right: 15px;
                    border-right: 1px solid #F0F1F2;
                    h1 {
                        font-size: 16px;
                    }
                    p {
                        margin-top: 4px;
                    }
                }
                .instructor-info {
                    padding-left: 15px;
                    .btn-2 {
                        margin-top: auto;
                    }
                }
            }
        }

        .instructor-content {
            flex: 1;
            .menu-header {
                ::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
                border-bottom: 1px solid #F0F1F2;
                .menu-container {
                    overflow-x: auto;
                }

                .head-menu {
                    cursor: pointer;
                    color: $primary-color;
                    font-size: 11px;
                    padding-bottom: 11px;
                    font-weight: 900;
                    white-space: nowrap;
                    margin-right: 28px;
                    &.active {
                        color: $primary-text;
                        border-bottom: 1px solid $primary-text;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .detail-info {
                flex: 1;
                overflow-y: auto;
                margin-top: 26px;
                padding-bottom: 10px;

                h2 {
                    font-size: 15px;
                    line-height: 23px;
                }

                .option-group {
                    margin-bottom: 25px;
                    min-height: fit-content;
                    p {
                        font-size: 13px;
                        line-height: 20px;
                        color: #7B88A0;
                    }
                    .skill-set {
                        display: flex;
                        flex-wrap: wrap;
                        .subject {
                            background-color: #E9F5FD;
                            border-radius: 4px;
                            font-size: 13px;
                            color: #7B88A0;
                            padding: 5px;
                            margin: 0 5px 5px 0;
                            &:last-child {
                                margin-right: 0;
                            }
                        }  
                    }
                }

                .blog {
                    display: flex;
                    padding: 15px 0;
                    border-bottom: 1px solid #F0F1F2;
                    min-height: fit-content;
                    img {
                        width: 89px;
                        height: 89px;
                        margin-right: 15px;
                    }
                    .blog-detail {
                        max-width: calc(100% - 104px);
                        flex: 1;

                        h2 {
                            font-size: 13px;
                            line-height: initial;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        p {
                            font-size: 13px;
                            line-height: 20px;
                            color: #7B88A0;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                        }
                        .read-more {
                            cursor: pointer;
                            margin-top: auto;
                            font-size: 13px;
                            color: #23A4EF;
                        }
                    }
                    &:first-child {
                        border-top: 1px solid #F0F1F2;
                    }
                }

                .load-more-btn {
                    padding: initial;
                    border-color: $main-bg;
                    color: $main-bg;
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .myinstructors-page {
        .instructors {
            margin: 0 -30px;
            padding: 0 30px;
        }
        .instructor-list {
            width: 400px;
            padding: 0 30px;
            .instructor-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 30px 30px 0 30px;

            .instructor-content {
                max-width: calc(100% - 240px);
                .detail-info {
                    .option-group {
                        .option-title {
                            margin-bottom: 6px;
                        }
                    }
                    .load-more-btn {
                        width: 88px;
                        margin: 14px auto 0;
                        height: 30px;
                    }
                    &.no-margin {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .myinstructors-page {
        .instructors {
            margin: 0 -15px;
            padding: 0 15px;
        }
        .instructor-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 27px 15px 0 15px;
            &.activate {
                display: flex;
            }
            .instructor-content {
                max-width: 100%;
                .detail-info {
                    .option-group {
                        .option-title {
                            margin-bottom: 10px;
                        }
                    }
                    .load-more-btn {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .instructor-profile {
        &.show-web-flex {
            display: none !important;
        }
        &.show-mobile-flex {
            display: flex !important;
        }
    }
    .instructor-content {
        max-width: 100% !important;
    }
}