@import '../../theme.scss';

@keyframes mobilesidebarShow {
    0% {  transform: translateX(-100%); }
    100% { transform: translateX(0); }
}
  
@keyframes mobilesidebarHide {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
}

.mobilesidebar-component {
    .mobilesidebar-wrapper {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 20;
        background: none;
        height: 100vh;
        font-size: 15px;
        display: flex;
        pointer-events: none;
        .mobilesidebar-block {
            flex: 1;
            height: 100%;
            display: none;
            pointer-events: auto;
        }
        .mobilesidebar-content {
            background: $main-bg;
            width: 268px;
            margin-left: -268px;
            padding-top: 15px;
            height: 100%;
            pointer-events: auto;
            color: white;

            .padding {
                margin: 0 30px;
            }

            .custom-links {
                border-top: 1px solid rgba(240, 241, 242, 0.1);
                border-bottom: 1px solid rgba(240, 241, 242, 0.1);
                padding: 10px 0;
                margin: 16px 30px;

                .get-30 {
                    color: $primary-color;
                    font-size: 13px;
                    cursor: pointer;
                    p {
                        margin-right: 6px;
                        font-weight: 900;
                    }
                    svg {
                        fill: $primary-color;
                    }
                }
                .primary-btn {
                    margin: 11px 0 15px;
                }
            }

            .sidebar-menu {
                position: relative;
                max-height: calc(100% - 235px);
                padding-bottom: 15px;
                overflow-y: auto;
                .menu-header {
                    color: $forth-color;
                    font-size: 17px;
                    margin-right: 30px;
                    padding: 14px 0 14px 30px;
                    cursor: pointer;
                    outline: none;
                    p {
                        font-size: 17px;
                    }
                    &:hover {
                        color: white;
                        text-decoration: none;
                        svg {
                            fill: white;
                        }
                    }
                }
                .menu-item {
                    color: $forth-color;
                    font-size: 17px;
                    margin-right: 30px;
                    padding: 14px 0 14px 30px;
                    border-top-right-radius: 45px;
                    border-bottom-right-radius: 45px;
                    min-height: fit-content;
                    p {
                        font-size: 17px;
                    }
                    &.active, &:hover {
                        color: white;
                        text-decoration: none;
                        svg {
                            fill: white;
                        }
                    }
                    &.active {
                        background: $fifth-color;
                    }
                    &.sub-menu-item {
                        padding-left: 60px;
                    }
                }
                .sub-menu {
                    height: 0;
                    overflow: hidden;
                    opacity: 0;
                }
        
                svg {
                    fill: $fifth-color;
                }
                .start-icon {
                    margin-right: 12px;
                }
                .end-icon {
                    margin-left: auto;
                }
            }
        }
    }

    &.active {
        .mobilesidebar-block {
            display: block;
        }
        .mobilesidebar-content {
            margin-left: 0;
            animation: mobilesidebarShow 0.2s ease-out;
        }
    }
    
    &.inactive {
        .mobilesidebar-block {
            display: none;
        }
        .mobilesidebar-content {
            margin-left: -268px;
            animation: mobilesidebarHide 0.2s ease-out;
        }
    }
}

@media screen and (min-width: 992px) {
    .mobilesidebar-component {
        display: none;
    }
}