.joinlesson-component {
    width: 100%;
    height: 47px;
    align-items: center;
    justify-content: center;
    background: #24C1A5;
    display: flex;
    color: white;
    font-size: 18px;
    font-weight: 900;
    margin-top: -47px;
    .join-btn {
        border-radius: 4px;
        background-color: white;
        color: #24C1A5;
        width: 51px;
        outline: none;
        border: none;
        height: 30px;
        font-size: 15px;
        font-weight: 900;
        margin-left: 17px;
    }
    &.active {
        animation: notificationShow 0.2s ease-out;
        margin-top: 0;
    }
    &.inactive {
        animation: notificationHide 0.2s ease-out;
        margin-top: -47px;
    }
}

@media screen and (max-width: 991px) {
    .joinlesson-component {
        display: none !important;
    }
}

@keyframes notificationShow {
    0% {  transform: translateY(-100%); }
    100% { transform: translateY(0); }
}
  
@keyframes notificationHide {
    0% { transform: translateY(200%); }
    100% { transform: translateY(100%); }
}