@import '../../theme.scss';

.settings-page {
    height: 100%;

    .settings-container {
        max-width: 530px;
        padding: 0;
    }

    p {
        font-size: 15px;
        line-height: 24px;
    }

    .spinner {
        width: 25px;
        height: 25px;
        border-width: 5px;
    }

    .save-btn {
        width: 100%;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
		
    .custom-flag {
        padding: 0;
        margin: 0;
        outline: none;
        .flag-select__btn {
            display: flex;
            align-items: center;
            outline: none;
        }	
        .flag-select__option {
            padding: 0;
            margin: 0;
        }
        .selected--flag--option {
                padding: 0;
        }
        img {
                top: 0;
        }
    }

    .settings-header {
        border-bottom: 1px solid #F0F1F2;

        .head-menu {
            cursor: pointer;
            color: $primary-color;
            font-size: 11px;
            padding-bottom: 11px;
            font-weight: 900;
            white-space: nowrap;
            &.active {
                color: $primary-text;
                border-bottom: 1px solid $primary-text;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .settings-body {
        flex: 1;
        overflow-y: auto;

        h2 {
            font-size: 15px;
            line-height: 23px;
        }

        #section-profile {
            padding-top: 39px;
        }

        #section-info, #section-password {
            .btn-2 {
                margin-top: 5px;
            }
        }

        #section-how-it {
            .accordion {
                border-top: 1px solid #E7EBEE;
                &:last-child {
                    border-bottom: 1px solid #E7EBEE;
                }
            }
        }

        .option-group {
            margin-bottom: 25px;

            .option-select {
                .selectbox-container {
                    background-color: white;
                    border: 2px solid #E3E8EC;
                    height: 40px;
                    padding: 0 15px;
                    svg {
                        fill: $primary-color;
                    }
                }
                .options {
                    border-width: 2px;
                    .option {
                        height: 40px;
                        padding-left: 15px;
                    }
                }
            }

            ::placeholder {
                color: rgba(32, 54, 98, 0.4);
            }

            .option-note {
                border: 2px solid #E3E8EC;
                font-size: 15px;
                height: 140px;
                border-radius: 4px;
            }

            .photo-area {
                .avatar {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    margin-right: 22px;
                }
                label {
                    margin: 0;
                }
                .upload_btn {
                    color: $main-bg;
                    border-color: $main-bg;
                    padding: initial;
                    height: 40px;
                }
            }

            .check-box {
                margin-right: 15px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .settings-page {
        padding: 27px 30px 10px 30px;
        .header-container {
            justify-content: space-between;
        }
        #section-info, #section-password {
            padding-top: 38px;
            .section-header {
                margin-bottom: 34px;
            }
        }
        #section-how-it {
            padding-top: 44px;
        }
        #section-suspend {
            p {
                margin: 38px 0 32px;
            }
        }
        .settings-body {
            .option-group {
                .option-title {
                    margin-bottom: 6px;
                }
                
                .type-item {
                    width: 167px;
                    margin-right: 15px;
                }

                .option-note {
                    padding: 8px 13px;
                }

                .photo-area {
                    .upload_btn {
                        width: 127px;
                    }
                }

                &.picture-option {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #F0F1F2;
                    margin-bottom: 39px;
                }
            }
            .submit-btn {
                margin-top: 5px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .settings-page {
        padding: 27px 15px 10px 15px;
        .settings-header {
            ::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .header-container {
            overflow-x: auto;
            .head-menu {
                margin-right: 25px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        #section-info, #section-password {
            padding-top: 23px;
            .section-header {
                margin-bottom: 19px;
            }
        }
        #section-how-it {
            padding-top: 9px;
        }
        #section-suspend {
            p {
                margin: 24px 0 25px;
            }
        }
        .settings-body {
            .option-group {
                .option-title {
                    margin-bottom: 10px;
                }

                .type-item {
                    width: calc(50% - 7px);
                    margin-right: auto;
                }

                .option-note {
                    padding: 11px 16px;
                }

                .photo-area {
                    label {
                        flex: 1;
                        .upload_btn {
                            width: 100%;
                        }
                    }
                }

                &.check-option {
                    margin-top: -17px;
                }
            }
        }
    }
}