@import '../../theme.scss';

.referral-page {
    height: 100%;

    .settings-container {
        padding: 20px;
    }

    p {
        font-size: 15px;
        line-height: 24px;
    }
    .bold{
        font-weight: bold;
        display: contents;
    }

    .settings-body {
        flex: 1;
        overflow-y: auto;

        h2 {
            font-size: 15px;
            line-height: 23px;
        }

        .option-group {
            margin-bottom: 25px;
        }
    }
        .data-area {
        flex: 1;
        .cell-1 {
            width: 40%;
        }
        .cell-2 {
            width: 30%;
        }
        .cell-3 {
            width: 30%;
        }
        .data-header {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            padding: 0 30px;
            font-size: 15px;
            font-weight: 700;
            .cell {
                padding: 17px 15px;
            }
        }
        .data-list {
            flex: 1;
            max-height: calc(100% - 50px);
            overflow-y: auto;
            font-size: 15px;
            .data-row {
                display: flex;
                padding: 0 30px;
                border-bottom: 1px solid #EDEEF0;
                outline: none;
                &.selected {
                    background: rgba(255, 0, 86, 0.0417);
                    border-top: 1px solid #FF0056;
                    border-bottom: 1px solid #FF0056;
                }
                .cell {
                    padding: 15px;
                    .active {
                        cursor: pointer;
                        color: $primary-color;
                        svg {
                            fill: $primary-color;
                            margin-right: 9px;
                        }
                    }
                }
                &:nth-of-type(odd) {
                    background-color: #F1F3F480;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .settings-page {
        padding: 27px 30px 10px 30px;
    }
}

@media screen and (max-width: 991px) {
    .settings-page {
        padding: 27px 15px 10px 15px;
    }
}