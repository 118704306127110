@import '../../theme.scss';

.schedulelesson-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    h3 {
        text-align: center;
    }
    .spinner {
        width: 25px;
        height: 25px;
        border-width: 5px;
    }
    .instructor-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .instructors {
            flex: 1;
        }
        .instructor-container {
            cursor: pointer;
            .instructor {
                padding: 15px 0;
                border-bottom: 1px solid #F0F1F2;
                display: flex;
                .avatar {
                    background-color: #24C1A5;
                    margin-right: 16px;
                }
                .info {
                    flex: 1;
                    justify-content: space-between;
                    height: 45px;
                    max-width: calc(100% - 80px);
                    h4 {
                        font-size: 15px;
                        font-weight: 900;
                    }
                    p {
                        font-size: 13px;
                        color: #7B88A0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            &:hover, &.selected {
                background-color: #F4F6F7;
            }
        }
    }
    .content-area {
        flex: 1;

        .date-content {
            display: flex;
            .date-select-area {
                padding-bottom: 25px;
                min-height: fit-content;
                    
                .region {
                    font-size: 15px;
                    svg {
                        margin-right: 10px;
                    }
                }
                .calendar {
                    margin-bottom: 25px;
                }
            }
            .time-select {
                flex: 1;
                border: 1px solid #E7EBEE;
                min-height: fit-content;
                .time-list {
                    .time-container {
                        height: 40px;
                        margin-bottom: 15px;
                        min-height: fit-content;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .sub-item {
                            height: 40px;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                        .time-item {
                            width: 100%;
                            border: 1px solid #E7EBEE;
                        }
                        .confirm-item {
                            border: 1px solid #E7EBEE;
                            display: none;
                            color: white;
                            background-color: $primary-color;
                            &.disabled {
                                opacity: 0.5;
                                pointer-events: none;
                            }
                        }
                        &.active {
                            height: auto;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .time-item {
                                background-color: $main-bg;
                                color: white;
                            }
                            .sub-item {
                                width: calc(50%);
                            }
                            .confirm-item {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .schedule-options {
            flex: 1;
            .selected-date {
                border-bottom: 1px solid #F0F1F2;
                svg {
                    fill: $primary-color;
                    margin-right: 25px;
                }
            }
            .option-list {
                .option-group {
                    margin-bottom: 25px;
                    min-height: fit-content;

                    .option-select {
                        .selectbox-container {
                            background-color: white;
                            border: 2px solid #E3E8EC;
                            height: 40px;
                            padding: 0 15px;
                            svg {
                                fill: $primary-color;
                            }
                        }
                        .options {
                            border-width: 2px;
                            .option {
                                height: 40px;
                                padding-left: 15px;
                            }
                        }
                    }

                    .type-item {
                        cursor: pointer;
                        height: 40px;
                        border: 2px solid #E7EBEE;
                        border-radius: 4px;
                        text-align: center;
                        &.active {
                            background-color: $primary-text;
                            color: white;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    ::placeholder {
                        color: rgba(32, 54, 98, 0.4);
                    }

                    .option-note {
                        border: 2px solid #E3E8EC;
                        font-size: 15px;
                        height: 140px;
                        border-radius: 4px;
                    }
                }
            }
            .success-area {
                flex: 1;

                .success-content {
                    text-align: center;
                    h2 {
                        font-size: 24px;
                    }
                    img {
                        margin: 30px 0;
                    }
                    p {
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .schedulelesson-page {
        .instructor-list {
            width: 400px;
            padding: 0 30px;
            .instructors {
                margin: 0 -30px;
                padding: 0 30px;
            }
            .instructor-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 25px 30px 18px 30px;

            .date-content {
                .time-select {
                    min-width: 272px;
                    // height: calc(100% - 88px);
                    height: 408px;
                    .time-header {
                        height: 95px;
                        border-bottom: 1px solid #E7EBEE;
                        h1 {
                            font-size: 18px;
                            margin: 19px 0 14px;
                        }
                        p {
                            color: #7B88A0;
                        }
                    }
                    .time-list {
                        padding: 15px;
                        height: calc(100% - 95px);
                        max-height: calc(100% - 95px);
                        overflow-y: auto;
                    }
                    .repeat-area{
                        margin: 10px;
                    }
                }
            }

            .schedule-options {
                .selected-date {
                    padding-bottom: 15px;
                }
                .option-list {
                    padding-top: 25px;
                    .option-group {
                        .option-title {
                            margin-bottom: 6px;
                        }
                        
                        .type-item {
                            width: 167px;
                            margin-right: 15px;
                        }

                        .option-note {
                            padding: 8px 13px;
                        }
                    }
                    .submit-btn {
                        margin-top: 5px;
                    }
                }
                .success-area {
                    .success-content {
                        max-width: 530px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .schedulelesson-page {
        .instructor-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            .instructors {
                margin: 0 -15px;
                padding: 0 15px;
            }
            &.activate {
                display: flex;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 15px;
            &.activate {
                display: flex;
            }
            .date-content {
                flex-direction: column;
                .time-select {
                    .time-header {
                        border-bottom: none;
                        h1 {
                            font-size: 18px;
                            margin: 25px 0 5px;
                        }
                        p {
                            color: #7B88A0;
                        }
                    }
                    .time-list {
                        padding: 25px 30px;
                    }
                }
            }

            .schedule-options {
                .selected-date {
                    padding-bottom: 8px;
                }
                .option-list {
                    padding-top: 10px;
                    .option-group {
                        .option-title {
                            margin-bottom: 10px;
                        }

                        .type-item {
                            width: calc(50% - 7px);
                            margin-right: auto;
                        }

                        .option-note {
                            padding: 11px 16px;
                        }
                    }
                    .submit-btn {
                        margin-top: -10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1340px) {
    .schedulelesson-page {
        .content-area {
            .date-content {
                flex-direction: column;
            }
        }
    }
}

.calendar {
    width: 100% !important;
    border-color: #E7EBEE !important;
    color: #203662;
    padding-top: 17px;
    font-family: Avenir;

    .react-calendar__navigation {
        justify-content: center;
        margin-bottom: 22px;
        height: initial;
    }
    
    .react-calendar__navigation__label {
        flex-grow: 0 !important;
        min-width: 120px;
        font-size: 16px;
        color: #7B88A0;
        &:hover, &:focus, &:active {
            background-color: white;
        }
    }

    .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__next-button, .react-calendar__navigation__prev-button {
        display: flex;
        align-items: center;
        justify-content: center;
        fill: #7B88A0;
        &:hover, &:focus, &:active {
            background-color: white;
        }
    }

    .react-calendar__month-view__weekdays {
        border-bottom: 1px solid #E7EBEE;
        .react-calendar__month-view__weekdays__weekday {
            padding-bottom: 10px;
            abbr {
                text-decoration: none;
                cursor: auto;
                color: #7B88A0;
                font-size: 15px;
                font-weight: normal;
            }
        }
    }

    .react-calendar__tile {
        color: #203662;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: rgba(123, 136, 160, 0.35);
    }

    .react-calendar__month-view__days {
        padding: 6px 0;
    }

    .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
        background-color: white;
    }

    .react-calendar__month-view__days__day {
        padding: 0;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        &:hover {
            background-color: white !important;
        }

        &.react-calendar__tile--active {
            background-color: white;

            abbr {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #23A4EF;
                color: white;
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
}