@import '../../theme.scss';

.reviewinstructors-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    .spinner {
        width: 25px;
        height: 25px;
        border-width: 5px;
    }
    .instructor-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .instructors {
            flex: 1;
        }
        .instructor-container {
            cursor: pointer;
            .instructor {
                padding: 15px 0;
                border-bottom: 1px solid #F0F1F2;
                display: flex;
                .avatar {
                    background-color: #E75D23;
                    margin-right: 16px;
                }
                .info {
                    flex: 1;
                    justify-content: space-between;
                    max-width: calc(100% - 60px);
                    .name-area {
                        justify-content: space-between;
                        p {
                            font-size: 11px;
                        }
                    }
                    h4 {
                        font-size: 15px;
                        font-weight: 900;
                    }
                    p {
                        font-size: 13px;
                        color: #7B88A0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            &:hover, &.selected {
                background-color: #F4F6F7;
            }
        }
    }
    .review-area {
        flex: 1;
        position: relative;
        .review-content {
            flex: 1;

            .instructor-name {
                font-size: 18px;
                font-weight: 500;
                height: 50px;
                padding-top: 13px;
                border-bottom: 1px solid #F0F1F2;
            }
            .compose-review {
                flex: 1;                
                .star-area {
                    flex: 1;
                    h4 {
                        font-size: 23px;
                        font-weight: 900;
                        span {
                            color: $primary-color;
                        }
                    }
                    p {
                        margin: 42px 0 8px;
                        color: #7B88A0;
                        font-size: 15px;
                    }
                }
                .review-input {
                    background: rgba(8, 51, 73, 0.04);
                    border-radius: 4px;
                    padding: 0 15px;
                    ::placeholder {
                        color: #7B88A0;
                    }
                    .review-text {
                        flex: 1;
                        border: none;
                        outline: none;
                        height: 100%;
                        background: initial;
                        min-height: 50px;
                        line-height: 23px;
                        padding: 14px 0;
                    }
                    svg {
                        margin-left: 40px;
                        fill: $primary-color;
                        cursor: pointer;
                    }
                }
            }
            .view-review {
                .review-message {
                    display: flex;
                    .initial {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
                    }
                    .review-info {
                        flex: 1;
                        p {
                            font-size: 15px;
                            line-height: 23px;

                            a {
                                color: $primary-color;
                            }
                        }
                        .explain {
                            font-size: 13px;
                            color: $sixth-color;
                            margin-bottom: 5px;
                            span {
                                color: $primary-color;
                            }
                        }
                        .my-review {
                            background-color: #F4F6F7;
                            margin-top: 5px;
                            border-radius: 4px;
                            .feedback-area {
                                display: flex;
                                flex-wrap: wrap;
                                .feedback {
                                    margin: 0 30px 10px 0;
                                    label {
                                        margin: 0 4px 0 0;
                                        font-size: 15px;
                                        color: $sixth-color;
                                    }
                                    .star-ratings {
                                        display: flex !important;
                                        align-items: center;
                                        .star-container {
                                            display: flex !important;
                                            align-items: center;
                                        }
                                    }
                                    &:nth-child(2n) {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                        .btn-area {
                            .btn-2 {
                                border-radius: 4px;
                                min-height: 30px;
                                font-size: 13px;
                                padding: initial;
                                font-weight: 500;
                            }
                            .edit-btn {
                                border-color: #073146;
                                color: #073146;
                            }
                            .del-btn {
                                border-color: #FF0056;
                                color: #FF0056;
                                &.disabled {
                                    opacity: 0.5;
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .reviewinstructors-page {
        .instructor-list {
            width: 400px;
            padding: 0 30px;
            .instructors {
                margin: 0 -30px;
                padding: 0 30px;
            }
            .instructor-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .review-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 0 30px 18px 30px;
            .view-review {
                padding-top: 40px;
                .review-message {
                    margin-bottom: 28px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .my-review {
                    padding: 14px 17px;
                    .feedback-area {
                        align-items: center;
                        .feedback {
                            margin: 0 30px 10px 0;
                            &:nth-child(2n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .btn-area {
                    margin-top: 10px;
                    .edit-btn {
                        border-color: #073146;
                        color: #073146;
                        margin-right: 10px;
                        width: 46px;
                    }
                    .del-btn {
                        border-color: #FF0056;
                        color: #FF0056;
                        width: 63px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .reviewinstructors-page {
        .instructor-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
            .instructors {
                margin: 0 -15px;
                padding: 0 15px;
            }
        }
        .review-area {
            width: 100%;
            display: none;
            padding: 15px;
            .view-review {
                .review-message {
                    margin-bottom: 76px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .my-review {
                    padding: 12px 14px;
                    .feedback-area {
                        flex-direction: column;
                        .feedback {
                            margin-bottom: 6px;
                        }
                    }
                    p {
                        margin-top: 13px;
                    }
                }
                .btn-area {
                    justify-content: space-between;
                    margin-top: 15px;
                    .btn-2 {
                        width: calc(50% - 6px);
                    }
                }
            }
            &.activate {
                display: flex;
            }
        }
    }
}