@import '../../theme.scss';

.credit-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;
            position: relative;
            height: 100%;

            .credit-content {
                display: flex;
                margin: auto;
                background: white;
                position: relative;
                width: 100%;
                border-radius: 8px;
                display: flex;
                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    .icon-close {
                        transform: rotate(45deg);
                    }
                }
                .img-area {
										position: relative;
                }
                .content-area {
                    h1 {
                        font-size: 32px;
                    }
                    p {
                        font-size: 15px;
                        line-height: 24px;
                    }
                    h2 {
                        font-size: 15px;
                        margin-bottom: 6px;
                    }
                    .url-btn {
                        height: 40px;
                        border: 2px solid #E3E8EC;
                        border-radius: 4px;
                    }
                    .url-area {
                        justify-content: space-between;
                        padding-right: 15px;
                        .url-input {
                            flex: 1;
                            border: none;
                            outline: none;
                            padding-left: 15px;
                            overflow: hidden;
                            color: $primary-text;
                        }
                        .predend-text {
                            cursor: pointer;
                            color: $primary-color;
                            font-size: 12px;
                            font-weight: 900;
                            margin-left: 10px;
                        }
                    }
                    .share-btn {
                        justify-content: center;
                        cursor: pointer;
                        p {
                            margin-left: 6px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
    }
    .credit-content {
        max-width: 700px;
        .img-area {
            width: 247px;
            border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
						img {
							width: 80%;
						}
        }
        .content-area {
            padding: 37px 45px 45px;
            flex: 1;

            h1 {
                margin-bottom: 27px;
            }
            h2 {
                margin-top: 34px;
            }
            .url-area {
                margin-bottom: 30px;
            }
            .btn-area {
                flex-wrap: wrap;
            }
            .share-btn {
                width: calc(50% - 7px);
                margin-bottom: 15px;
                &:first-child {
                    margin-right: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 0 15px;
    }
    .credit-content {
        max-width: 345px;
        flex-direction: column;
        .img-area {
            height: 187px;
            border-top-right-radius: 8px;
						border-top-left-radius: 8px;
						img {
							height: 80%;
						}
        }
        .content-area {
            padding: 19px 30px 30px;
            h1 {
                margin-bottom: 20px;
                text-align: center;
            }
            h2 {
                margin-top: 21px;
            }
            .url-area {
                margin-bottom: 15px;
            }
            .btn-area {
                flex-direction: column;
            }
            .share-btn {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
}