h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    font-weight: 900;
}

p {
    font-size: 15px;
}

input, textarea {
    box-shadow: none;
    outline: none;
}

.inactive-icon {
    fill: $secondary-color;
}

.shadow-object {
    box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
}

.v-r {
    display: flex;
    flex-direction: column;
}

.v-c {
    display: flex;
    align-items: center;
}

.h-c {
    display: flex;
    justify-content: center;
}

.svg-icon {
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
    }
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.btn-2 {
    font-weight: 900;
    font-size: 15px;
    border-radius: 4px;
    color: white;
    min-height: 40px;
    border: none;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    font-family: inherit;

    &:focus {
        outline: none;
        border: none;
    }
    &:hover {
        color: white;
    }
}

.btn-2-outline {
    background: none;
    border: 1px solid $primary-color;
    color: $primary-color;
    &:focus {
        border: 1px solid $primary-color;
    }
    &:hover {
        color: $primary-color;
    }
}

.primary-btn {
    background: $primary-color;
}

.secondary-btn {
    background: $third-color;
}

.form-group {
    padding: 0 7.5px;
    label {
        font-weight: 900;
        font-size: 15px;
    }
}

.sub-menu-enter {
    height: initial !important;
    opacity: 1 !important;
    transition: all 200ms ease-out;
}
.sub-menu-enter-done {
    height: initial !important;
    opacity: 1 !important;
    transition: all 400ms ease-out;
}
.sub-menu-exit {
    height: initial !important;
    opacity: 0 !important;
    transition: all 200ms ease-out;
}
.sub-menu-exit-done {
    height: 0 !important;
    opacity: 0 !important;
    transition: all 400ms ease-out;
}

.spinner {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (min-width: 992px) {
    .show-web {
        display: block;
    }

    .show-web-flex {
        display: flex;
    }

    .show-mobile, .show-mobile-flex {
        display: none !important;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        background: #1B1F25; 
        border-radius: 40px;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #BDBDBD; 
    }
}

@media screen and (max-width: 991px) {
    .show-mobile {
        display: block;
    }

    .show-web, .show-web-flex {
        display: none !important;
    }

    .show-mobile-flex {
        display: flex;
    }
}

@font-face {
    font-family: 'Avenir';
    src: url('./Assets/Font/Avenir-Roman.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir';
    font-weight: 800;
    src: url('./Assets/Font/Avenir-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir';
    font-weight: 900;
    src: url('./Assets/Font/Avenir-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir';
    font-weight: 500;
    src: url('./Assets/Font/Avenir-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'CircularStd';
    src: url('./Assets/Font/CircularStd-Book.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProDisplay';
    font-weight: bold;
    src: url('./Assets/Font/SFProDisplay-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProDisplay';
    font-weight: 600;
    src: url('./Assets/Font/SFProDisplay-Semibold.ttf') format('truetype');
}