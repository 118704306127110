@import '../../theme.scss';

.pendinglesson-page {
    height: 100%;
    .options-area {
        display: flex;
        .search-input {
            flex: 1;
            border: none;
            .input-text {
                font-size: 13px;
            }
        }
        .count-area {
            font-size: 13px;
            color: #7B88A0;
        }
    }
    .spinner {
        width: 25px;
        height: 25px;
        border-width: 5px;
    }
    .outline-btn {
        color: $primary-text;
        border-color: $primary-text;
        padding: initial;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .data-area {
        flex: 1;
        .cell-1 {
            width: 16%;
        }
        .cell-2 {
            width: 20%;
        }
        .cell-3 {
            width: 12%;
        }
        .cell-4 {
            width: 12%;
        }
        .cell-5 {
            width: 12%;
        }
        .cell-6 {
            flex: 1;
        }
        .data-header {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            padding: 0 30px;
            font-size: 11px;
            font-weight: 700;
            .cell {
                padding: 17px 15px;
            }
        }
        .data-list {
            flex: 1;
            max-height: calc(100% - 50px);
            overflow-y: auto;
            font-size: 15px;
            .data-row {
                display: flex;
                padding: 0 30px;
                border-bottom: 1px solid #EDEEF0;
                outline: none;
                &.selected {
                    background: rgba(255, 0, 86, 0.0417);
                    border-top: 1px solid #FF0056;
                    border-bottom: 1px solid #FF0056;
                }
                .cell {
                    padding: 15px;
                }
                .cell-1, .cell-2 {
                    color: $primary-color;
                }
                .cell-6 {
                    p {
                        max-width: calc(100% - 60px);
                    }
                }
                &:nth-of-type(odd) {
                    background-color: #F1F3F480;
                }
            }
        }
    }
    .pending-detail {
        height: 100%;
        max-height: 100%;
        overflow-y: auto; 
        .detail-list {
            border-top: 1px solid #EDEEF0;
            .detail-row {
                height: 40px;
                border-bottom: 1px solid #EDEEF0;
                .detail-title {
                    padding-right: 5px;
                }
                .detail-content {
                    flex: 1;
                    &.name {
                        color: $primary-color;
                    }
                }
            }
        }
        .group {
            h2 {
                font-size: 15px;
                margin-bottom: 7px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .pendinglesson-page {
        overflow: hidden;
        .cancel-btn {
            width: 57px;
            max-width: 57px;
            min-height: 25px;
            // margin-left: auto;
            font-size: 13px;
            font-weight: 500;
        }
        .options-area {
            align-items: center;
            padding: 0 30px;
            .search-input {
                height: 50px;
                padding-left: 0;
            }
            .count-area {
                height: 50px;
                .page-count {
                    margin-right: 9px;
                }
                .arrow-btn {
                    margin-right: 20px;
                }
                .combo-area {
                    p {
                        margin-right: 7px;
                    }
                }
            }
        }
        .data-area {
            max-height: calc(100% - 50px);
        }
        .pending-detail {
            padding: 22px 30px 10px 30px;
            h1 {
                margin-bottom: 18px;
                font-size: 23px;
            }
            .detail-list {
                margin-bottom: 39px;
                .detail-row {
                    .detail-title {
                        width: 200px;
                    }
                }
            }
            .group {
                margin-bottom: 23px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .pendinglesson-page {
        .options-area {
            flex-direction: column;
            .search-input {
                height: 40px;
                border-bottom: 1px solid #EDEEF0;
                padding: 0 15px;
            }
            .count-area {
                height: 40px;
                padding: 0 15px 0 4px;
                border-bottom: 1px solid #EDEEF0;
                p {
                    margin: 0 4px;
                }
                .combo-area {
                    margin-left: auto;
                    p {
                        margin: 0 15px 0 0;
                    }
                }
            }
        }
        .data-area {
            max-height: calc(100% - 80px);
            overflow-y: auto;
            .mobile-row {
                padding: 0 15px;
                min-height: fit-content;
                .data-row {
                    padding: 12px 0;
                    flex: 1;
                    display: flex;
                    .col-header {
                        font-weight: 700;
                        font-size: 11px;
                        margin-bottom: 9px;
                    }
                    p {
                        line-height: 23px;
                    }
                    .left-col {
                        width: 40%;
                    }
                    .active {
                        cursor: pointer;
                        color: $primary-color;
                        svg {
                            fill: $primary-color;
                            margin-right: 9px;
                        }
                    }
                    .right-col {
                        flex: 1;
                        font-size: 15px;
                    }
                }
                .cancel-btn {
                    margin-bottom: 15px;
                }
                &:nth-of-type(odd) {
                    background-color: #F1F3F480;
                }
            }
        }
        .pending-detail {
            padding: 0 15px 10px 15px;
            .notification {
                height: 48px;
                background-color: #24C1A5;
                justify-content: space-between;
                margin: 0 -15px;
                padding: 0 15px;
                font-weight: 900;
                color: white;
                p {
                    flex: 1;
                    max-width: calc(100% - 114px);
                }
                .join-btn {
                    width: 100px;
                    height: 30px;
                    background-color: white;
                    color: #24C1A5;
                    font-size: 15px;
                    font-weight: 900;
                    border: none;
                    outline: none;
                    border-radius: 4px;
                }
            }
            h1 {
                margin-bottom: 18px;
                font-size: 20px;
            }
            .detail-list {
                margin-bottom: 27px;
                .detail-row {
                    min-height: 40px;
                    height: initial;
                    .detail-title {
                        width: 135px;
                    }
                }
            }
            .group {
                margin-bottom: 18px;
            }
            .cancel-btn {
                max-width: 180px;
                width: 100%;
                margin: 15px 0;
            }
        }
    }
}