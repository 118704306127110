@import '../../theme.scss';

.receipt-page {
    height: 100%;
    .options-area {
        display: flex;
        .search-input {
            flex: 1;
            border: none;
            .input-text {
                font-size: 13px;
            }
        }
        .count-area {
            font-size: 13px;
            color: #7B88A0;
        }
    }
    .data-area {
        .view-btn {
            color: $primary-color;
            cursor: pointer;
        }
    }
    .receipt-detail {
        height: 100%;
        max-height: 100%;
        overflow-y: auto; 

        .detail-row {
            display: flex;
            border-bottom: 1px solid #EDEEF0;

            .detail-block {
                h1 {
                    font-size: 23px;
                }
                .paragraph {
                    margin-top: 18px;
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
        .pay-header {
            h2 {
                font-size: 11px;
            }
        }
        .pay-data {
            border-top: 1px solid #EDEEF0;
            border-bottom: 1px solid #EDEEF0;
            padding: 10px 0;
        }
        .completed {
            color: #33AC12;
        }
        .print-btn {
            padding: initial;
            color: $main-bg;
            border-color: $main-bg;
        }
    }
}

@media screen and (min-width: 992px) {
    .receipt-page {
        overflow: hidden;
        .options-area {
            align-items: center;
            padding: 0 30px;
            .search-input {
                height: 50px;
                padding-left: 0;
            }
            .count-area {
                height: 50px;
                .page-count {
                    margin-right: 9px;
                }
                .arrow-btn {
                    margin-right: 20px;
                }
                .combo-area {
                    p {
                        margin-right: 7px;
                    }
                }
            }
        }
        .data-area {
            height: calc(100% - 50px);
            .table {
                display: block;
                height: 100%;
                overflow-y: auto;
                color: $primary-text;
                thead {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                }
                th {
                    background-color: white;
                    border: none;
                    vertical-align: top;
                    font-size: 11px;
                }
                th, td {
                    border-left: none;
                    border-right: none;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                td {   
                    font-size: 15px;
                }
                tbody {
                    tr {
                        &:nth-of-type(odd) {
                            background-color: #F1F3F480;
                        }
                    }
                } 
            }
        }
        .receipt-detail {
            padding: 0 30px 19px 30px;
            .detail-row {
                justify-content: space-between;
                padding: 22px 0 28px;
            }
            .pay-header {
                margin-top: 23px;
                padding-bottom: 10px;
            }
            .first-col {
                width: 146px;
            }
            .last-col {
                width: 193px;
                margin-left: auto;
            }
            .receipt-footer {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #EDEEF0;
                padding: 46px 0 53px;
                justify-content: space-between;
            }
            .print-btn {
                width: 70px;
                margin-top: 17px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .receipt-page {
        .options-area {
            flex-direction: column;
            .search-input {
                height: 40px;
                border-bottom: 1px solid #EDEEF0;
                padding: 0 15px;
            }
            .count-area {
                height: 40px;
                padding: 0 15px 0 4px;
                p {
                    margin: 0 4px;
                }
                .combo-area {
                    margin-left: auto;
                    p {
                        margin: 0 15px 0 0;
                    }
                }
            }
        }
        .data-area {
            max-height: calc(100% - 80px);
            .table {
                display: none !important;
            }
            .mobile-row {
                padding: 0 15px;
                .data-row {
                    padding: 12px 0;
                    flex: 1;
                    .col-header {
                        font-weight: 700;
                        font-size: 11px;
                        margin-bottom: 9px;
                    }
                    p {
                        line-height: 23px;
                    }
                    .left-col {
                        width: 40%;
                    }
                    .right-col {
                        flex: 1;
                    }
                }
                &:nth-of-type(odd) {
                    background-color: #F1F3F480;
                }
            }
        }
        .receipt-detail {
            padding: 0 15px 50px 15px;
            .detail-row {
                flex-direction: column;
                padding: 25px 0;
                min-height: fit-content;
                .left-block {
                    margin-bottom: 33px;
                }
            }
            .pay-table {
                min-height: fit-content;
            }
            .pay-header {
                margin-top: 28px;
                padding-bottom: 25px;
            }
            .first-col {
                width: 130px;
            }
            .last-col {
                width: 80px;
                margin-left: auto;
            }
            .receipt-footer {
                border-bottom: 1px solid #EDEEF0;
                padding: 44px 0 25px;
                img {
                    margin-bottom: 12px;
                }
            }
            .print-btn {
                margin-top: 30px;
            }
        }
    }
}