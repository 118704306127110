@import '../../theme.scss';

.toggle-component {
    position: relative;
    border-radius: 100px;
    cursor: pointer;
    background: $primary-color;
    min-width: 47px;
    height: 30px;
  
    .handle {
        position: absolute;
        top: 3px;
        left: 20px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: white;
        transition: left 0.15s;
        -webkit-transition: left 0.15s;
        -moz-user-select: none;
        -webkit-user-select: none;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
  
    &.disable {
        background: grey !important;
    }

    &.disable-color {
        background: grey;
        .handle {
            left: 3px;
        }
    }
  
    &.off {
        background: #5CD4C9;
        
        .handle {
            left: 3px;
        }
    }
}