$main-bg: #083349;

$primary-text: #203662;
$secondary-text: rgba(32, 54, 98, 0.4);

$primary-color: #23A4EF;
$secondary-color: #607783;
$third-color: #2E5365;
$forth-color: #92A7B2;
$fifth-color: #284C5F;
$sixth-color: #7B88A0;