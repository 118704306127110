.accordion-component {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 13px 0;
    color: #203662;
    .title-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: auto;
        cursor: pointer;
        .accordion-title {
            width: calc(100% - 44px);
            font-size: 15px;
            line-height: 24px;
            font-weight: 900;
        }
        .icon {
            cursor: pointer;
        }
        .icon-open {
            fill: #23A4EF;
        }
        .icon-close {
            fill: #203662;
            transform: rotate(45deg);
        }
    }
    .accordion-content {
        font-size: 15px;
        line-height: 22px;
        transition: height 200ms 200ms, opacity 200ms 0ms;
        opacity: 0.0;
        height: 0px;
        overflow: hidden;
        display: block;
        width: 100%;
        
        &.active {
            opacity: 1.0;
            height: initial;
            padding-top: 17px;
            transition: height 200ms 0ms, opacity 200ms 100ms;
        }
    }
}