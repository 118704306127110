@import '../../theme.scss';

.header-component {
    position: fixed;
    z-index: 10;
    color: white;
    background: $main-bg;
    height: 60px;

    .header-wrapper {
        justify-content: space-between;
        flex: 1;
        position: relative;
        .title-area {
            .title {
                font-weight: 500;
            }
        }
        .notify-area {
            position: relative;
            cursor: pointer;
            .new-notify {
                width: 7px;
                height: 7px;
                background: #FF0056;
                border-radius: 50%;
                position: absolute;
                right: -4px;
                top: 3px;
                display: none;
            }
            &.active {
                .new-notify {
                    display: block;
                }
            }
        }
        .action-area {
            .avatar-area {
                cursor: pointer;
                .avatar {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 6px;
                }
            }
        }
    }

    .account-menu {
        position: absolute;
        background-color: white;
        right: 0;
        bottom: -194px;
        color: $primary-text;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 160px;
        border-radius: 4px;
        padding: 9px 0;
        outline: none;
        p {
            padding: 4px 0 4px 15px;
            cursor: pointer;
            &:hover {
                color: white;
                background-color: $primary-text;
            }
            &:first-child {
                &:hover {
                    color: $primary-text;
                    background-color: white;
                }
            }
            &:last-child {
                border-top: 1px solid #F0F1F2;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .header-component {
        padding: 0 30px;
        width: calc(100% - 262px);
        .header-wrapper {
            .notify-area {
                margin-right: 27px;
            }
            .title-area {
                .title {
                    font-size: 23px;
                }
            }
            .action-area {
                .get-30 {
                    color: $primary-color;
                    font-size: 13px;
                    cursor: pointer;
                    p {
                        margin-right: 6px;
                        font-weight: 900;
                    }
                    svg {
                        fill: $primary-color;
                    }
                }
                .secondary-btn {
                    margin: 0 15px 0 28px;
                }
                .primary-btn {
                    margin-right: 33px;
                }
            }
        }

        &.expanded {
            width: calc(100% - 80px);
        }
    }
}

@media screen and (max-width: 991px) {
    .header-component {
        padding: 7px 15px 0;
        width: 100%;
        .header-wrapper {
            .title-area {
                .title {
                    font-size: 18px;
                    margin-left: 14px;
                }
            }
            .notify-area {
                margin-right: 18px;
            }
        }
    }
}